<template>
  <div class="sh-table-wrap">
    <div v-if="showOperation" class="operation">
      <div class="left-btn-wrap">
        <div class="left-btn">
          <slot name="left">
            <template v-for="(item, opIndex) in operationFilter">
              <ElButton
                v-if="item.title === '删除' || item.title === '废弃'"
                :key="`${item.prop}${opIndex}`"
                type="danger"
                plain
                size="small"
                :class="`erp-op-${item.prop}`"
                @click="operationFun(item.prop, item.state, item.menu)"
              >
                {{ $t(item.title) }}
              </ElButton>
              <ElButton
                v-else-if="opIndex === 0 || item.prop == 'exportTable'"
                :key="`${item.prop}-${opIndex}`"
                type="primary"
                size="small"
                :class="`erp-op-${item.prop}`"
                @click="operationFun(item.prop, item.state, item.menu)"
              >
                {{ $t(item.title) }}
              </ElButton>
              <ElButton
                v-else
                :key="`${item.prop}`"
                size="small"
                :class="`erp-op-${item.prop}`"
                @click="operationFun(item.prop, item.state, item.menu)"
              >
                {{ $t(item.title) }}
              </ElButton>
            </template>
            <div v-if="!!tip" class="table-tip">
              &emsp;&nbsp;<i class="el-icon-info" />&nbsp;{{ tip }}
            </div>
          </slot>
          <slot name="leftAction" />
        </div>
      </div>
      <div class="right-btn">
        <slot name="operation" />

        <div class="oper-right">
          &nbsp;
          <ColumnSetting
            v-if="setting===1"
            :columns="columns"
            :active-name="activeName"
            @confirm="confirm"
          />
          <ShowFiled v-if="setting===true" :title="columns" @updateColumn="updateColumn" />
            &nbsp;&nbsp;
          <el-tooltip
            v-if="getOperationExportExcel.length>0"
            effect="dark"
            :content="$t('导出')"
            placement="top"
          >
            <el-button
              style="padding: 7px 10px;"
              plain
              size="mini"
              @click="operationFun('exportExcel')"
            >
              <i
                class="iconfont icon icon-daochu"
                style="font-size: 16px;"
              />
            </el-button>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div ref="shTable" class="sh-table">
      <VxeTable
        ref="tableRef"
        :key="columnKey"
        :empty-text="$t('暂无数据')"
        :data="tableData"
        :min-height="minHeight"
        :max-height="clientHeight"
        stripe
        :size="size"
        highlight-hover-row
        :show-footer-overflow="showFooterOverflow"
        :tooltip-config="{
          theme: tooltipEffect,
          enterDelay: 100,
          leaveDelay: 100,
          enterable: true
        }"
        :column-config="{
          resizable: resizable,
          useKey: 'id'
        }"
        :row-config="{
          useKey: 'id',
          isCurrent: true,
          isHover: true
        }"
        :sort-config="{
          remote: true,
          showIcon: showIcon
        }"
        :checkbox-config="checkboxConfig"
        :scroll-x="scrollX"
        :scroll-y="scrollY"
        :footer-method="footerMethods"
        v-bind="$attrs"
        @sort-change="sortMethod"
        @clear-sort="clearSort"
        @checkbox-change="selectionChange"
        @checkbox-all="selectionChange"
        @resizable-change="resizableChange"
        @cell-click="cellClick"
        @edit-closed="editClosed"
      >
        <VxeColumn
          v-if="expand"
          type="expand"
          :width="40"
          fixed="left"
        >
          <template #content="{ row, rowIndex }">
            <slot name="expand" :row="row" :row-index="rowIndex" />
          </template>
        </VxeColumn>
        <VxeColumn
          v-if="index"
          key="seqIndex"
          type="seq"
          fixed="left"
          :title="$t('序号')"
          align="center"
          :min-width="100"
        />
        <VxeColumn
          v-if="(selection || getOperationExportExcel.length>0)"
          key="checkbox"
          fixed="left"
          type="checkbox"
          title=""
          align="center"
          width="40"
        />
        <VxeColumn
          v-if="showAction"
          key="actionWidth"
          fixed="left"
          :title="$t('操作')"
          :width="actionWidth"
          :min-width="100"
          align="left"
        >
          <template #header>
            <span style="margin-left:6px;">{{ $t('操作') }}</span>
          </template>
          <template #default="{ row, rowIndex }">
            <div class="erp-table-edit">
              <slot name="action" :row="row" :row-index="rowIndex">
                <span
                  v-for="(i, idx) in tablebutton"
                  v-show="functionState(i.prop, row)"
                  :key="idx"
                  :class="`erp-table-op-${i.prop}`"
                  @click="buttonFun(row, i.prop, rowIndex)"
                >
                  {{ $t(i.title) }}
                </span>
              </slot>
              <slot name="otherAction" :row="row" :row-index="rowIndex" />
            </div>
          </template>
        </VxeColumn>
        <slot />
        <VxeColumn
          v-for="(column, indexes) of tableColumns"
          :key="column.label"
          :title="column.label"
          :field="column.prop"
          :type="column.type"
          :fixed="column.fixed"
          :show-overflow="column.tooltip"
          :sortable="column.sortable"
          :formatter="column.formatter"
          :align="column.align"
          :header-align="column.headerAlign"
          :footer-align="column.footerAlign"
          :class-name="column.className"
          :title-prefix="column.titlePrefix"
          :edit-render="column.editRender"
          :min-width="column.width || column.minWidth || 100"
        >
          <template v-if="$slots[`${column.prop}Header`]" #header="{ row, rowIndex }">
            <slot
              :name="`${column.prop}Header`"
              :row="row"
              :row-index="rowIndex"
            />
          </template>
          <template v-if="column.link" #default="{ row, rowIndex }">
            <slot
              :name="column.prop"
              :row="row"
              :column="column"
              :row-index="rowIndex"
            />
          </template>
          <template v-else-if="column.hideText" #default="{ row,rowIndex }">
            <div class="table-text">
              <slot
                :name="column.prop"
                :row="row"
                :column="column"
                :row-index="rowIndex"
              >
                <HideableText :text="row[column.prop]" :hide-positon="$getHidePosition(column.hideText.idType, column.hideText.country || row.documentType || row.entChinaFlag)" />
                <template v-if="!row[column.prop]">
                  {{ column.hideText.default }}
                </template>
              </slot>
            </div>
          </template>
          <template v-else-if="!column.tooltip" #default="{ row,rowIndex }">
            <div class="table-text">
              <slot
                :name="column.prop"
                :row="row"
                :column="column"
                :row-index="rowIndex"
              >
                {{ column.filer?formatterText(column.filer, row, column.prop, column):row[column.prop] }}
              </slot>
            </div>
          </template>
          <template #edit="{ row, rowIndex }">
            <slot
              :name="`${column.prop}Edit`"
              :row="row"
              :column="column"
              :row-index="rowIndex"
            />
          </template>
        </VxeColumn>
      </VxeTable>
    </div>

    <div class="sh-table-pagination">
      <ElPagination
        v-if="pageState"
        :current-page="pageNo"
        :page-sizes="pageOption"
        :page-size.sync="pageSize"
        :layout="pageParms.layout"
        :total="total"
        @size-change="sizeChange"
        @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script>
import ShowFiled from './ShowFiled.vue'
import { generatorSchema, simpleExcel } from '@/utils/excel'
import ColumnSetting from '../setting/ColumnSetting.vue'
import { formPost, post } from '@/http/request'
import { getTbHeight } from '@/utils/utils'
import { summation } from '@/utils/common'
import { VXETable } from 'vxe-table'
import i18n from '@/lang'

/**
 * 数组 根据配置排序
 * @import 排序配置从 1 开始
 * @param arr 数组
 * @param config 排序配置
 */

function sortArrayByConfig(arr, config) {
  return arr.map((item, index) => {
    return {
      ...item,
      sort: index + 1,
    }
  }).map((item) => {
    if (item.prop in config) {
      return {
        ...item,
        sort: config[item.prop] - 1,
      }
    }
    return item
  }).sort((a, b) => {
    return a.sort - b.sort
  })
}

export default {
  name: 'ShTables',
  components: {
    ShowFiled,
    ColumnSetting,
  },
  props: {
    minHeight: {
      type: Number,
      default: 40,
    },
    // 是否显示序号
    index: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    // 是否显示复选框
    selection: {
      type: Boolean,
      default: true,
    },
    // 是否显示扩展
    expand: {
      type: Boolean,
      default: false,
    },
    // table 列字段配置
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    tableData: {
      type: Array,
      default() {
        return []
      },
    },
    height: {
      type: [String, Number],
      default: 0,
    },
    resizable: {
      type: Boolean,
      default: true,
    },
    pageState: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    // table 列表操作按钮
    button: {
      type: [Array, Boolean],
      default: () => {
        return false
      },
    },
    tip: {
      type: String,
      default: '',
    },
    functionVerification: {
      type: Object,
      default: () => {
        return {}
      },
    },
    haveTab: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    pageIndex: {
      type: [String, Number],
      default: 0,
    },
    pageSizes: {
      type: [String, Number],
      default: 50,
    },
    pageOption: {
      type: Array,
      default() {
        return [10, 50, 100, 200]
      },
    },
    actionWidth: {
      type: [String, Number],
      default: '160px',
    },
    scrollX: {
      type: Object,
      default() {
        return {
          gt: 100,
          enabled: false,
        }
      },
    },
    scrollY: {
      type: Object,
      default() {
        return {
          gt: 520,
          enabled: true,
        }
      },
    },
    checkboxConfig: {
      type: Object,
      default() {
        return {
          // checkField: 'id',
          checkAll: false,
          highlight: true,
        }
      },
    },
    delName: {
      type: String,
      default: 'id',
    },
    url: {
      type: [Array, Object],
      default: () => {
        return {}
      },
    },
    size: {
      type: String,
      default: 'medium',
    },
    tooltipEffect: {
      type: String, // dark/light
      default: 'dark',
    },
    operation: {
      type: [Array, Boolean],
      default: () => {
        return false
      },
    },
    filter: {
      type: Object,
      default: () => {
        return {}
      },
    },
    summaryFields: {
      type: Array,
      default() {
        return []
      },
    },
    footerMethod: Function,
    showFooterOverflow: {
      type: [Boolean, String], // dark/light
      default: 'tooltip',
    },
    fileName: {
      type: String,
      default: '',
    },
    showOperation: {
      type: Boolean,
      default: true,
    },
    setting: {
      type: [Boolean, Number],
      default: true,
    },
    activeName: {
      type: [String, Number],
      default: '',
    },
    footerName: {
      type: String,
      default: i18n.t('合计'),
    },

    /**
     * 菜单排序
     */
    menuOrder: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // sumText: {
    //   type: String,
    //   default: i18n.t('合计'),
    // },
    // selectOnIndeterminate: {
    //   type: Boolean,
    //   default: true,
    // },
    // indent: {
    //   type: Number,
    //   default: 16,
    // },
    // lazy: {
    //   type: Boolean,
    //   default: false,
    // },
    // load: {
    //   type: Function,
    //   default() {
    //     return () => {}
    //   },
    // },
    // treeProps: {
    //   type: Object,
    //   default() {
    //     return { hasChildren: 'hasChildren', children: 'children' }
    //   },
    // },
  },
  data() {
    return {
      pageNo: 1,
      pageSize: this.$store.state.pageParms.size,
      pageThrottle: false,
      selectionList: [],
      dialogVisible: false,
      clientHeight: 'auto',
      getAuthority: this.$store.state.menu.routerParmsNow,
      exportName: this.$store.state.menu.routerParmsNow.title,
      tableColumns: [],
      columnKey: 'key',
    }
  },
  computed: {
    columnKeys() {
      return this.tableColumns.map(item => item.prop).join('') + this.activeName
    },
    getChangeHeight() {
      return this.$store.getters.getChangeHeight
    },
    pageParms() {
      return this.$store.state.pageParms
    },
    // finalData() {
    //   // 特殊处理，与监听数据变化，滚动条移动冲突
    //   // if (this.$attrs['edit-config']) {
    //   //   return Object.freeze(deepClone(this.tableData))
    //   // }
    //   return Object.freeze(this.tableData)
    // },
    // table 内部操作按钮
    tablebutton() {
      if (this.button === false) {
        const table = this.getAuthority.authority.table || []
        return Object.freeze(table)
      } else {
        return Object.freeze(this.button)
      }
    },
    // table 上方功能按钮
    operationFilter() {
      return Object.freeze(this.getOperationBtn.filter(item => this.functionState(item.prop, item)))
    },
    getOperationBtn() {
      if (this.operation === false) {
        if (this.getAuthority.authority.operation === void 0) {
          return []
        }
        const operationBtn = this.getAuthority.authority.operation.filter(e => e.prop !== 'exportExcel')
        if (this.menuOrder && Object.keys(this.menuOrder).length > 0) {
          return sortArrayByConfig(operationBtn, this.menuOrder)
        }
        return operationBtn
      } else {
        if (this.menuOrder && Object.keys(this.menuOrder).length > 0) {
          return sortArrayByConfig(this.operation, this.menuOrder)
        }
        return this.operation
      }
    },
    getOperationExport() {
      if (this.operation === false) {
        const operations = this.getAuthority.authority.operation
        if (operations === void 0) {
          return []
        }
        return operations.filter(e => e.prop === 'exportExcel')
      } else {
        return this.operation
      }
    },
    getOperationExportExcel() {
      return Object.freeze(this.getOperationExport.filter(item => item.prop === 'exportExcel'))
    },
    publicHeight() {
      return this.$store.getters.getHeight.max
    },
  },
  watch: {
    columnKeys() {
      this.$nextTick(() => {
        this.columnKey = Date.now().toString(16)
      })
    },
    getChangeHeight() {
      this.getTableHeight()
    },
    publicHeight() {
      this.getTableHeight()
    },
    pageIndex(newVal) {
      if (newVal !== 0) {
        this.pageNo = newVal
      }
    },
    tableData() {
      this.selectionList = []
      this.getTableHeight()
      this.$nextTick(() => {
        try {
          let bodyWrapper = this.$refs.tableRef.$el.querySelector('.vxe-table--body-wrapper')
          setTimeout(() => {
            bodyWrapper.scrollTo && bodyWrapper.scrollTo(0, 0)
          }, 5)
        } catch (error) {
          window.console.log(error)
        }
      })
    },
    columns() {
      if (!this.setting) {
        this.updateColumn(this.columns.map(item => item.label))
      }
    },
  },
  created() {
    if (!this.setting) {
      this.updateColumn(this.columns.map(item => item.label))
    }
  },
  mounted() {
    this.pageNo = this.pageIndex
    this.pageSize = this.pageSizes
    this.getTableHeight()
  },
  activated() {
    this.getTableHeight()
  },
  methods: {
    handleColumn() {
      this.updateColumn(this.columns.map(item => item.label))
    },
    selectionChange({ records }) {
      this.selectionList = Object.freeze(records)
      this.$emit('selectionChange', records)
    },
    footerMethods({ columns, data }) {
      // debugger
      if (this.footerMethod) {
        return this.footerMethod({ columns, data })
      }
      let sum = [this.footerName]
      if (data.length === 0) {
        return [sum]
      }
      this.summaryFields.forEach(field => {
        let index = columns.findIndex(item => item.property === field.prop)
        if (field.method) {
          sum[index] = field.method(data)
        } else {
          sum[index] = summation(data, field.prop, true)
        }
      })
      return [sum]
    },
    // 格式化数据显示
    formatterText(filer, row, prop, column) {
      if (filer) {
        if (this.filter[filer]) {
          return this.filter[filer](row, prop)
        }
        if (this.$Rule[filer]) {
          return this.$Rule[filer](row[prop])
        }
      } else if (column.formatter) {
        if (typeof column.formatter === 'function') {
          return column.formatter({
            cellValue: row[prop],
            row,
            column,
          })
        }
        if (Array.isArray(column.formatter)) {
          const func = column.formatter[0]
          const parama = column.formatter.slice(1)
          return VXETable.formats.get(func)['cellFormatMethod']({
            cellValue: row[prop],
            row,
            column,
          }, ...parama)
        }
        if (typeof column.formatter === 'string') {
          return VXETable.formats.get(column.formatter)['cellFormatMethod']({
            cellValue: row[prop],
            row,
            column,
          })
        }
      }
      return row[prop]
    },
    sizeChange(size) {
      this.pageSize = size
      this.pageNo = 1
      this.currentChange(1)
    },
    currentChange(page) {
      this.pageNo = page
      this.pageSizeSearch()
    },
    pageSizeSearch() {
      if (this.pageThrottle === true) {
        return
      }
      this.pageThrottle = true
      this.$emit('pageSizeSearch', this.pageNo, this.pageSize)
      this.pageThrottle = false
    },
    functionState(name, val, index) {
      // 需要从functionVerification 接受一个Object 判断
      if (this.functionVerification[name] !== void 0) {
        return this.functionVerification[name](val, index)
      }
      return true
    },
    // table上方按钮函数
    operationFun(prop, state, menuName) {
      if (prop === 'del') {
        let list = this.selectionList.map(item => item.id).join(',')
        if (list === '') {
          this.$Info.info(this.$t('请选择选项！'))
        } else {
          this.del(list)
        }
      } else if (prop === 'exportExcel') {
        let name = this.getOperationExportExcel.find(item => item.prop === 'exportExcel').menuName
        this.exportExcel(name)
      } else {
        this.$emit('actionMethod', prop, this.selectionList)
      }
    },
    // table内部按钮函数
    buttonFun(val, prop, rowIndex) {
      if (prop === 'del') {
        this.del(val.id)
        return
      }
      this.$emit('actionMethod', prop, val, rowIndex)
    },
    updateColumn(column) {
      this.tableColumns = this.columns.filter(item => column.includes(item.label))
      this.refreshColumn()
    },
    // 刷新 列， 去除错位问题
    refreshColumn() {
      this.$nextTick(() => {
        this.$refs.tableRef && this.$refs.tableRef.refreshColumn()
        this.$refs.tableRef && this.$refs.tableRef.recalculate()
      })
    },
    reloadRow(rows, record, field) {
      return this.$refs.tableRef && this.$refs.tableRef.reloadRow(rows, record, field)
    },
    loadData(data) {
      this.$refs.tableRef && this.$refs.tableRef.loadData(data)
    },
    reloadData(data) {
      this.$refs.tableRef && this.$refs.tableRef.reloadData(data)
    },
    updateData(rows, record, field) {
      this.$refs.tableRef && this.$refs.tableRef.updateData()
    },
    updateFooter() {
      this.$nextTick(() => {
        this.$refs.tableRef && this.$refs.tableRef.updateFooter()
        this.refreshColumn()
      })
    },
    resizableChange() {
      this.refreshColumn()
    },
    getTableHeight() {
      if (this.height) {
        this.clientHeight = this.height
        return
      }
      this.$nextTick(() => {
        this.clientHeight = getTbHeight(this, 'shTable', this.pageState)
        // if (this.haveTab) {
        //   this.clientHeight = this.clientHeight - 50
        // }
        this.refreshColumn()
      })
    },
    sortMethod(row) {
      this.$emit('sortMethod', row)
    },
    clearSort(row) {
      console.log(row)
    },
    setActiveRow(row) {
      this.$refs.tableRef && this.$refs.tableRef.setActiveRow(row)
    },
    cellClick(scope) {
      this.$emit('cell-click', scope)
    },
    editClosed(scope) {
      this.$emit('edit-closed', scope)
    },
    hideColumn(fieldOrColumn) {
      this.$refs.tableRef && this.$refs.tableRef.hideColumn(fieldOrColumn)
    },
    showColumn(fieldOrColumn) {
      this.$refs.tableRef && this.$refs.tableRef.showColumn(fieldOrColumn)
    },
    isEditByRow(row) {
      return this.$refs.tableRef.isEditByRow(row)
    },
    revertData(row) {
      this.$refs.tableRef.revertData(row)
    },
    clearEdit(row) {
      return this.$refs.tableRef.clearEdit(row)
    },
    del(val) {
      this.$confirm(this.$t('您确定要删除该选项吗?'), this.$t('提示'), {
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'warning',
      }).then(() => {
        let data = {}
        let url = this.url
        data[this.delName] = url.dataIsArray ? val.split(',') : val
        if (url.type == 'post') {
          post(url.del, data).then(res => {
            if (res.code == 0) {
              this.$Info.success(this.$t('删除成功'))
              this.$emit('pageSizeSearch', this.pageNo, this.pageSize)
            }
          })
          return
        }
        formPost(url.del, data).then(res => {
          if (res.code == 0) {
            this.$Info.success(this.$t('删除成功'))
            this.$emit('pageSizeSearch', this.pageNo, this.pageSize)
          }
        })
      }).catch(() => '')
    },
    exportExcel(file) {
      let name = this.fileName || file || this.exportName
      let list = []
      let title = this.columns
      let val = this.selectionList
      let titleProp = title
      for (let i = 0; i < val.length; i++) {
        let prop = {}
        for (let item = titleProp.length; item--;) {
          prop[titleProp[item].prop] = this.formatterText(titleProp[item].filer, val[i], titleProp[item].prop, titleProp[item])
        }
        list.push(prop)
      }
      if (list.length) {
        const schema = generatorSchema(title, {
          title: 'label',
        })
        simpleExcel(list, schema, `${this.$t(name)}.xlsx`)
      } else {
        this.$notify({
          title: this.$t('警告'),
          message: this.$t('请选择需要导出的数据！'),
          type: 'warning',
        })
      }
    },
    confirm(columns) {
      this.tableColumns = Object.freeze(columns)
    },
  },
}
</script>

<style lang="scss">
  .vxe-table--render-default {
    // font-family: Microsoft YaHei, STXihei, '\534E\6587\7EC6\9ED1', '\9ED1\4F53', serif !important;
    font-family: "Microsoft YaHei","微软雅黑","Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB",Arial,sans-serif;
  }
  .vxe-header--column .vxe-resizable{
    right: -6px;
  }
.sh-table-wrap {
  padding: 0 10px;
  .sh-table {
    .el-table {
      // color: #444444;
      thead {
        color: #F5F7FA;
      }
      th.gutter {
        width: 18px !important;
      }
    }
  }
  .vxe-table,

  .vxe-table thead th {
    background-color: #F5F7FA;
  }
  // .vxe-table tfoot .vxe-cell--item {
  //   padding-left: 6px;
  // }
  .vxe-table--render-default .vxe-body--row.row--stripe{
    background-color: #FAFBFC;
  }
  .vxe-table--render-default .vxe-body--row.row--stripe.row--current{
    background-color: #d7f2ff;
  }
  .vxe-table--render-default .vxe-body--row.row--checked{
    background-color: #d7f2ff;
  }
  code,pre{
    // font-family: Microsoft YaHei, STXihei, '\534E\6587\7EC6\9ED1', '\9ED1\4F53', serif !important;
    display: contents;
  }
  .vxe-table--render-default .vxe-cell {
    color: #444;
    white-space: normal;
  }
  .is--footer .vxe-table--render-wrapper .vxe-table--fixed-left-wrapper .vxe-table--body-wrapper{
    overflow-x: hidden !important;
    padding-bottom: 17px;
  }

  .sh-table-pagination {
    padding: 10px 0;
    text-align: left;
  }
  .operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 14px 10px 14px;

    .el-button--danger.is-plain {
      color: #f42430;
      background: #fff;
      border-color: #f42430;
    }
  }
  .left-btn-wrap {
    margin-bottom: -10px;
    .left-btn {
      .el-button {
        margin-bottom: 10px;
      }
    }
  }

  .right-btn {
    display: flex;
    align-items: center;
    //height: 32px;
    .oper-right {
      display: inline-flex;
      > i {
        margin-left: 20px;
      }
    }
  }
}
</style>
