<template>
  <ElForm
    ref="ErpSearchForm"
    :inline="true"
    disabled
    :model="searchValue.data"
    label-position="right"
    :size="size"
    class="erp-search"
    @submit.native.prevent
  >
    <template
      v-for="(item, index) of finalFields"
    >
      <Pop
        v-if="searchValue.data[item.prop]"
        :key="index"
        ref="searchPop"
        v-model="searchValue.data[item.prop]"
        :width="width"
        :item="item"
        @getParentId="getParentId"
      />
    </template>
    <ElFormItem style="margin-right:0;">
      <SearchSetting
        v-if="setting"
        :columns="value.title"
        :active-name="activeName"
        @confirm="confirm"
      />
    </ElFormItem>
  </ElForm>
</template>
<script>
import SearchSetting from '../setting/SearchSetting.vue'
import Pop from './pop/pop.vue'
export default {
  name: 'ErpSearch',
  components: {
    //组成局部组件
    Pop,
    SearchSetting,
  },
  props: {
    value: {
      type: [Array, Object],
      required: true,
      default: () => {
        return {}
      },
    },
    labelWidth: {
      type: Number,
      default: 85,
    },
    width: {
      type: Number,
      default: 220,
    },
    size: {
      type: String,
      default: 'small',
    },
    parent: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: true,
    },
    activeName: {
      type: [Number, String],
      default: '0',
    },
    setting: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      restList: {},
      showNumber: 3,
      openSelect: false,
      refreshFlag: false,
      setFields: [],
      timer: null,
    }
  },
  computed: {
    searchValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    finalFields() {
      if (this.setting) {
        return this.setFields
      }
      return this.value.title
    },
  },
  watch: {
    activeName() {
      this.initWidth()
    },
  },
  mounted() {
    // el已经挂载
    this.initWidth()
    this.init()
    this.restList = Object.assign({}, this.value.data)
    this.$emit('loadSuccess')
  },
  methods: {
    getWidth() {
      let searchForm = document.querySelector('.page-resize-main')
      if (searchForm) {
        return searchForm.getBoundingClientRect().width - 40
      }
      return this.$store.getters.getWidth.max - 180 - 20 - 20
    },
    init() {
      if (this.showAll) {
        return
      }
      this.timer = null
      let observe = new ResizeObserver((entries) => {
        for (let entry of entries) {
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            this.handleWidth(entry.contentRect.width - 40)
          }, 300)
        }
      })
      const searchForm =  document.querySelector('.page-resize-main')
      observe.observe(searchForm)
      this.$on('hook:beforeDestroy', () => {
        observe.disconnect()
        observe = null
        clearTimeout(this.timer)
      })
    },
    initWidth() {
      const searchWidth = this.getWidth()
      this.handleWidth(searchWidth)
    },
    handleWidth(searchWidth) {
      if (this.showAll || (this.openSelect && !this.refreshFlag)) {
        return
      }
      this.refreshFlag = false
      const fields = this.finalFields
      // 初始按钮区域宽度
      let totalWidth = 220
      if (this.setting) {
        totalWidth = 280
      }
      for (let index = 0; index < fields.length; index++) {
        // const item = fields[index]
        // const labelWidth = item.labelWidth || this.labelWidth
        const width = this.width // widthMap[item.type]
        const margin = 10
        if (totalWidth + width + margin >= searchWidth) {
          this.showNumber = index - 2
          return
        }
        totalWidth = totalWidth + width + margin
      }
      this.showNumber = fields.length
    },
    confirm(fields) {
      this.setFields = Object.freeze(fields)
      this.refreshFlag = true
      this.$nextTick(() => {
        this.initWidth()
        this.setHeight()
      })
    },
    // 函数
    getValue() {
      let fields = this.finalFields
      let data = {}
      const originData = this.value.data
      fields.forEach(item => {
        if (item.endProp) {
          const value = originData[item.prop] || []
          data[item.prop] = value[0]
          data[item.endProp] = value[1]
        } else if (item.finalProp) {
          data[item.prop] = originData[item.prop]
          data[item.finalProp] = originData[item.finalProp]
        } else {
          data[item.prop] = originData[item.prop] && typeof originData[item.prop] === 'string' ? originData[item.prop].trim() : originData[item.prop]
        }
      })
      return data
    },
    // 获取父级id  例如 搜索条件为部门，多选，则选中哪个部门，将该部门id 返回,而不是返回自己id
    getParentId(k, v) {
      this.searchValue.data[k] = v
    },
    submit() {
      // try {
      //   if (this.parent) {
      //     this.$parent.$parent.submit()
      //   } else {
      //     this.$parent.submit()
      //   }
      // } catch (e) {
      //   console.log(e)
      // }
      // console.log(this)
      this.$emit('submit')
    },
    rest() {
      this.$refs.searchPop.forEach(key => {
        key.restText()
      })
      let list = this.restList
      for (let i in list) {
        if (typeof list[i] === 'string') {
          list[i] = null
        } else if (typeof list[i] === 'number') {
          list[i] = null
        } else if (Array.isArray(list[i])) {
          list[i] = []
        } else if (typeof list[i] === 'object' && list[i] !== null) {
          list[i] = {}
        }
      }
      this.searchValue.data = Object.assign({}, list)
      this.$refs['ErpSearchForm'].resetFields()
      this.$emit('resetSuccess')
    },
    setHeight() {
      this.$nextTick(() => {
        this.$store.commit('setChangeHeight')
      })
    },
  },
}
</script>

<style lang="scss">

.erp-search {
	display: flex;
	flex-wrap: wrap;
	padding: 16px 10px 6px 10px;
	border-bottom: 1px solid #eaeef8;

  .el-form-item {
		margin-bottom: 10px;
	}

  .el-form-item__label{
    padding-right: 2px;
		color: #555;
  }
  .inner-label{
    position: absolute;
    left: 15px;
    max-width: 180px;
    pointer-events: none;
    color: #999;
    font-size: 12px;
    line-height: 12px;
    top: 10px;
    background: #fff;
    transition: top 0.18s linear;
    margin-right: 1px;
    // transform: translateY(-50%);
    // 单行省略号
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .el-date-editor ~ .inner-label{
    left: 27px;
  }
  .is-fouce ~ .inner-label{
    top: -6px;
  }
  .form-item-hide {
    display: none;
  }
}

</style>
