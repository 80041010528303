import { storage } from '@/utils/storage'
import i18n from '@/lang/index'
const common = {
  state: () => ({
    // 字典表数据
    dictionary: { },
    // 产品类型
    businessType: { },
    // 所属部门 级联数据
    cascader: [],
    // 所属部门 有权限控制
    cascaderHasAuth: [],
    // 项目用户
    cascaderOption: { },

    // 渠道列表，用作下拉选项
    recruitChannel: [],
    industry: [], // 行业
    userList: [],
    documentType: Object.freeze([
      { value: '0', label: i18n.t('中国居民身份证') },
      { value: '1', label: i18n.t('越南身份证') },
      { value: '99', label: i18n.t('其他类型') },
    ]),
    cnyTypeList: storage.getItem('cnyTypeList') || [], // 货币全局列表
    cnyTypeUnitList: storage.getItem('cnyTypeUnitList') || [], // 货币全局列表
    cnyType: storage.getItem('cnyType') || null, // 货币全局值
    registrationAddress: Object.freeze(storage.getItem('registrationAddress') || []), // 注册地列表
  }),
  getters: {
    ///货币value:label 对象映射
    cnyTypeMap(state) {
      const cnyTypeList = state.cnyTypeList
      const map = cnyTypeList.reduce((total, currrent) => {
        total[currrent.value] = currrent.label
        return total
      }, {})
      return Object.freeze(map)
    },
    ///货币value:desc 对象映射
    cnyTypeUnitMap(state) {
      const cnyTypeList = state.cnyTypeUnitList
      const map = cnyTypeList.reduce((total, currrent) => {
        total[currrent.value] = currrent.label
        return total
      }, {})
      return Object.freeze(map)
    },
    userObject(state) {
      const userList = state.userList
      const map = userList.reduce((total, currrent) => {
        total[currrent.value] = currrent
        return total
      }, {})
      map['1'] = {
        value: '1',
        label: i18n.t('超级管理员'),
      }
      return Object.freeze(map)
    },
    registrationAddressObject(state) { // 注册地对象
      const data = state.registrationAddress.reduce((total, currrent) => {
        total[currrent.value] = currrent.label
        return total
      }, {})
      return Object.freeze(data)
    },
    documentTypeObject() {
      return Object.freeze({
        0: i18n.t('中国居民身份证'),
        1: i18n.t('越南身份证'),
        99: i18n.t('其他类型'),
      })
    },
  },
  mutations: {
    setDictionary(state, payload) {
      state.dictionary = payload || {}
    },
    setCascader(state, payload) {
      state.cascader = payload || []
    },
    setCascaderHasAuth(state, payload) {
      state.cascaderHasAuth = payload || []
    },
    setCascaderOption(state, payload) {
      state.cascaderOption = payload || {}
    },
    setBusinessType(state, payload) {
      state.businessType = payload || []
    },
    setRecruitChannel(state, payload) {
      state.recruitChannel = payload || []
    },
    setIndustry(state, payload) {
      state.industry = payload || []
    },
    setUserList(state, payload) {
      state.userList = payload || []
    },
    setCnyType(state, payload) {
      state.cnyType = payload || ''
      storage.setItem('cnyType', payload)
    },
    setCnyTypeList(state, payload) {
      state.cnyTypeList = payload || []
      storage.setItem('cnyTypeList', payload)
    },
    setCnyTypeUnitList(state, payload) {
      state.cnyTypeUnitList = payload || []
      storage.setItem('cnyTypeUnitList', payload)
    },
    setRegistrationAddress(state, payload) {
      state.registrationAddress = payload || []
      storage.setItem('registrationAddress', payload)
    },
  },
  actions: {},
}

export default common
